export const HOT_OFFER_TYPE = {
  BINANCE: 'BINANCE_CASHBACK_OFFER',
  OFFER: 'HOT_OFFER',
  SO_QUEST_OFFER: 'SO_QUEST_OFFER',
  POW_OFFER: 'POW_OFFER',
  POW_OFFER_CLAIM: 'POW_OFFER_CLAIM',
  REWARD_POOL: 'REWARD_POOL',
} as const;

// export const HOT_OFFER_DEFAULT_SORT_BY = 'new';
//
// export const HOT_OFFER_DEFAULT_ITEMS_PER_PAGE = 10;
//
// export const HOT_OFFER_MAGIC_ID_NECESSARY_FIELDS: Parameters<
//   typeof checkMagicIDForNecessaryData
// >[0]['searchParams'] = {
//   name: 1,
//   email: 1,
//   'wallets[]': 'EVM',
// };
