export const MAGIC_BOOST_AFFILIATE_URLS = {
  DEVELOPMENT: 'https://affiliate1.magicboost.io/#/authentication/login',
  PRODUCTION: 'https://affiliate2.magicsquare.io/#/authentication/login',
};

export const MAGIC_BOOST_ALLOWED_DOMAINS = [
  'https://affiliate1.magicboost.io',
  'https://affiliate2.magicsquare.io',
  'https://crm-msq-staging.antelopesystem.com',
];

export const MAGIC_BOOST_SUBMIT_YOUR_PROJECT_URL = 'https://magicsquare.io/submit-your-app/';
